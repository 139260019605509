<template>
  <div>
    <br />
    <div class="row">
      <div class="col-3">
        <label for="">Désignation de stock </label>
        <input type="text" v-model="stock.fullName" class="form-control" />
      </div>
      <div class="col-4">
        <label for="">Désignation d'article </label>

        <input
          type="text"
          :disabled="true"
          :value="stock.item ? stock.item.fullName : '--'"
          class="form-control"
        />
      </div>

      <div class="col-3">
        <label for="">Catégorie</label>
        <span v-if="stock.item">
          <input
            type="text"
            :disabled="true"
            :value="stock.item.fullName"
            class="form-control"
          />
        </span>
      </div>

      <div class="col-2">
        <label for="">Quantité</label>
        <input type="text" v-model="stock.quantity" class="form-control" />
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-3">
        <label for="">Raison</label>
        <select name="" id="" v-model="stock.reason" class="form-select">
          <option value="Achat auprès du fournisseur.">
            Achat auprès du fournisseur.
          </option>
          <option value="Règlement de stock.">Règlement de stock.</option>
          <option value="Correction d'une erreur de stock.">
            Correction d'une erreur de stock.
          </option>
          <option value="Saisie tardive des achats.">
            Saisie tardive des achats.
          </option>
        </select>
      </div>

      <div class="col-2">
        <label for="">Chambre </label>
        <select
          name=""
          id=""
          class="form-select"
          v-model="stock.coldRoom_reference"
        >
          <option
            v-for="(coldroom, index) in coldrooms"
            :key="index++"
            :value="coldroom.reference"
          >
            {{ coldroom.number }}
          </option>
        </select>
      </div>
      <div class="col">
        <label for="">Remarque</label>
        <input type="text" v-model="stock.remark" class="form-control" />
      </div>
    </div>
    <br />
    <div class="row justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="btn btn-success" @click="save(stock)">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      // stock: {},
    };
  },
  computed: {
    // ...mapGetters("item", {
    //   item: "getItem",
    // }),
    ...mapGetters("coldroom", {
      coldrooms: "getColdrooms",
    }),
    ...mapGetters("stock", {
      stock: "getStock",
    }),
  },
  methods: {
    async save(data) {
      // data.item_reference = this.item.reference;
      let reference = data.reference;
      let fullName = data.fullName;
      let item_reference = data.item_reference;
      let date = data.date;
      let remark = data.remark;
      let coldRoom_reference = data.coldRoom_reference;
      let reason = data.reason;
      let quantity = data.quantity;

      await this.$store.dispatch("stock/update", {
        reference,
        fullName,
        item_reference,
        date,
        remark,
        coldRoom_reference,
        reason,
        quantity,
      });
    },
  },
  beforeMount() {
    this.$store.dispatch("stock/show", this.$route.params.reference);
    this.$store.dispatch("coldroom/getAll");
  },
};
</script>
